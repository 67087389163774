import { Schema } from "mongoose";

export class Aword {
    name: string;
    qt: number;
}

export type WheelEventModel ={
    _id: Schema.Types.ObjectId;
    name: string;
    description: string;
    end:Date;
    runGame: number;
    runMax: number;
    staff: string[];
    master: string[];
    awards:  Aword[];
}


export class WheelEventFetchUrl{

    public static GetWheelEvent(name:string){
        return `wheelevent/${name}`;
    }

    public static GetAllWheelEvents(){
        return `wheelevent`;
    }

} 