import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useContext } from "react";


import { GeventFetchUrl, GeventModel } from "./../model/Gevent.model";
import CustomServices from "./../services/services";
import { LoginContext, PopUpContext } from "./../store/context";

import style from "./stylepage/Dashboard.module.css"


import ImgSettings from "./../source/src_icon/settings.png";
import ImgLogout from "./../source/src_icon/logout.png";
import ImgBackArrow from "./../source/src_icon/back-arrow.png";

function Dashboard() {

  // const [user, setUser] = useContext(LoginContext);
  const [popUp, setPopUp] = useContext(PopUpContext);

  async function Logout(e: React.MouseEvent<HTMLButtonElement, MouseEvent>){
    e.preventDefault();
    
    await CustomServices.sendFetch("auth/logout", {
        method: "GET",
      });

    document.location.reload();
  }

  function logoutPopup(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>){
    e.preventDefault();
    let message = <p className={style.popuplogout}>Confermi di voler uscire ?</p>
    let buttons = [
      <button className="btn" title="logout" onClick={Logout}>si</button>,
      <button className="btn" title="annulla"type="button" onClick={e => setPopUp({message:null, buttons:null})}>annulla</button>
    ]


    setPopUp({message, buttons, wrapClass: "btn-box_grid" })
  }

  function navToggle(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, idNav: string){
    e.preventDefault();
    let boxNav = document.getElementById(idNav);
    boxNav.classList.toggle(style["nav-open"]);
    
  }

  function navBack(idNav: string){
    let boxNav = document.getElementById(idNav);
    boxNav.classList.remove(style["nav-open"]);
    
  }

  function setStyleNavElement(e : any){
    return style.navElement + (e.isActive ? " "+ style.linkActive  : "");
  }

  return (
    <div className={style.main} id="nav">
        <nav  className={style.nav}>
            <div className={style["nav-toggle"]}>
              <button type="button" onClick={e => navToggle(e, "nav")}><img src={ImgBackArrow} title="apri\chiudi nav" alt="toggle arrow"/></button>
            </div>
            <NavLink to="settings" className={setStyleNavElement} onClick={e => navBack("nav")}>
              <img src={ImgSettings} title="Impostazioni" alt="Impostazioni"/>
              <span>Impostazioni</span>
            </NavLink>
            
            {/* <NavLink to="events" className={setStyleNavElement} onClick={e => navBack("nav")}>
              <img src={ImgEvents} title="eventi" alt="eventi"/>
              <span>Eventi</span>
            </NavLink> */}
            
            <NavLink to="logout" onClick={logoutPopup} className={style.navElement}>
              <img src={ImgLogout} title="Logout" alt="Logout"/>
              <span>Logout</span>
            </NavLink>
        </nav>
        <div className={style["box-outlet"]}>
          <div className={style.black} onClick={e => navBack("nav")}></div>
          <Outlet></Outlet>
        </div>
        
    </div>
  );
}
  
export async function loaderDashboardPage(){

  let geventsResp = await CustomServices.sendFetch<[GeventModel]>(GeventFetchUrl.GetAllGevents(), {
      method: "GET",
  })
  return geventsResp.data;
}

export default Dashboard;