import { ObjectId } from "mongoose";

export type CreateWheelCodeModel ={
    idWheelEvent: ObjectId,
}

export class UseWheelCodeDto {
    award: string 
}

export class WheelCodeFetchUrl{

    public static GenerateWheelCodeEvent() : string {
        return `wheelcode`;
    }

    public static UseWheelCode(codeId: string) : string {
        return `wheelcode/${codeId}/use`;
    }
} 