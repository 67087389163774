import { createContext } from "react";


export interface I_PopupContext {
    message: JSX.Element | null;
    buttons?: JSX.Element[] | null;
    wrapClass?: string ;
}

export interface I_UserContext {
    email:string;
    username:string;
    id:string;
}

type I_PopupContextUseState  = [I_PopupContext , React.Dispatch<React.SetStateAction<I_PopupContext>>]
type I_UserContextUseState  = [I_UserContext|null , React.Dispatch<React.SetStateAction<I_UserContext|null>>]
type I_UrlContextUseState  = [string|null , React.Dispatch<React.SetStateAction<string|null>>]



export  let PopUpContext = createContext<I_PopupContextUseState>(null as I_PopupContextUseState);
export  let LoginContext = createContext<I_UserContextUseState>(null);
export  let UrlContext = createContext<I_UrlContextUseState>(null);