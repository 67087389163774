import React, { useContext, useEffect, useState } from 'react';
import './App.module.css';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { I_UserContext, I_PopupContext, LoginContext, PopUpContext, UrlContext } from './store/context';
import WheelEvents, { loaderWheelEventsPage } from './page/WheelEvents';
import WheelEvent, {loaderWheelEventPage} from './page/WheelEvent';
import Dashboard from './page/Dashboard';
import Settings from './component/dashboard/settings/Settings';


import Root from './Root';
import Access from './page/Access';
import ConfirmMail from './page/service/ConfirmMail';
import RecoveryPassword from './page/service/RecoveryPassword';
import ChangeEmail from './page/service/ChangeEmail';

import { jwtDecode  } from "jwt-decode";


function App() {
  const [user, setUser] = useState<I_UserContext>(null);
  const [popup, setPopup] = useState<I_PopupContext>({message:null});
  const [url, setUrl] = useState<string>(null);

  
  useEffect(() => {
    let cookies =  decodeURIComponent(document.cookie).split(';')
    let jwt = cookies.find((row) => row.startsWith("Jwt_User="))?.split("=")[1];

    if(!jwt && user){
      setUser(null)
    }

    if(jwt){
      let user = jwtDecode(jwt) as {email:string, username: string, _id: string};
      setUser(() => {return {email: user.email, username: user.username, id:user._id}});
    }
  }, [])

  //TODO: creare pagina errori 24/06/2024
  //TODO: ottimizzare le immagini e le icone 24/06/2024
  //TODO: cambiare le immagini di prova 24/06/2024
  //TODO: creare paginazione eventi 24/06/2024
  //TODO: creare sezione di visualizzazione risultati di un evento per il ristoratore 24/06/2024

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root/>,
      loader: () => {return ""},
      //TODO:INSERT ERROR ELEMENT
      //errorElement: <p>Errore</p>,
      children:[
        {
          path:"dashboard",
          element: user ? <Dashboard/> : <Navigate to={"/"} />,
          children:[
            { index: true, element: <Navigate to="./settings" replace /> },
            {
              path: "events",
              element: <p>eventi</p>,
              // loader: async ({params}) => {
              //   return await loaderGeventPage(params.name)
              //}
            },
            {
              path: "settings",
              element: <Settings/>,
              // loader: async ({params}) => {
              //   return await loaderGamePage(params.name)
              // }
            }
          ]
        },
        // {
        //   path:"events",
        //   children: [
        //     {
        //       index:true,
        //       element:<Gevents/>,
        //       loader: async () => {
        //         return await loaderGeventsPage();
        //       }
        //     },
        //     {
        //       path: ":name",
        //       element: <Gevent/>,
        //       loader: async ({params}) => {
        //         return await loaderGeventPage(params.name)
        //       }
        //     },
        //     {
        //       path: ":name/play",
        //       element:<Game/>,
        //       loader: async ({params}) => {
        //         return await loaderGamePage(params.name)
        //       }
        //     }
        //   ],
        // },
        {
          path:"wheel",
          children: [
            {
              index:true,
              element:<WheelEvents/>,
              loader: async () => {
                return await loaderWheelEventsPage();
              }
            },
            {
              path: ":name",
              element: <WheelEvent/>,
              loader: async ({params}) => {
                return await loaderWheelEventPage(params.name)
              }
            },
          ],
        },
        {
          path:"access",
          element: user ?  <Navigate to={"/"} />: <Access/>
        },
        {
          path:"service",
          children:[
            {
              path:"user",
              children:[
                {
                  path:"confirmemail/:idmail",
                  element:<ConfirmMail/>
                },
                {
                  path:"changeemail/:idmail",
                  element:<ChangeEmail/>
                },
                {
                  path:"recoverypassword/:idmail",
                  element:<RecoveryPassword/>
                }
              ]
            }
          ]
        }
      ]
    },
  ]);
  
  

  return (
    <UrlContext.Provider value={[url, setUrl]}>
      <LoginContext.Provider value={[user, setUser]}>
        <PopUpContext.Provider value={[popup, setPopup]}>
              <RouterProvider router={router}/>
          </PopUpContext.Provider>
      </LoginContext.Provider>
    </UrlContext.Provider>
  )
}


export default App;
