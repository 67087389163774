import React, { useContext, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Wheel } from 'paramall-wheel-of-fortune'
import { WheelData } from "paramall-wheel-of-fortune/dist/components/Wheel/types";

import CustomServices from "./../services/services";
import { LoginContext, PopUpContext } from "./../store/context";
import WheelCodeGenerator from "./../component/staff/WheelCodeGenerator";

import style from "./stylepage/Wheelevent.module.css"
import Form from "./../component/form/Form";
import Input from "./../component/form/Input";
import { WheelEventFetchUrl, WheelEventModel } from "./../model/WheelEvent.model";
import { UseWheelCodeDto, WheelCodeFetchUrl } from "./../model/WheelCode.model";

import triangle from "./../source/triangle.png"
function WheelEvent() {
  let [user , setUser] = useContext(LoginContext);  
  let wheelEvent = useLoaderData() as WheelEventModel;


  const code = useState<string>("");
  const error = useState<{[name: string]: string|null}>({});

  const [popUp, setPopUp] = useContext(PopUpContext);

  const [isRotating, setIsRotating] = useState(false);
  const [isInPending, setIsInPending] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [prize, setPrize] = useState<string>("riprova");
  const [awards, setAwards] = useState<WheelData[]>([]);

  const isStaff = user?.id &&  wheelEvent.staff.includes(user.id);
  const isMaster = user?.id &&  wheelEvent.master.includes(user.id);


  useEffect(() => {
    // let awardsList: WheelData[] = [];

    // wheelEvent.awards.forEach(element => {
    //   awardsList.push({
    //     option: "?",
    //     style: { textColor: 'black', fontSize:25, fontFamily:"Helvetica"}
    //   })
    // });
    const data = [
      { option: '?' },
      { option: '?' },
      { option: '?' },
      { option: '?' },
      { option: '?' },
      { option: '?' },
      { option: '?' },
      { option: '?' },
    ]
    setAwards(data);
  }, [])

  async function validateSpeenCode(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault();
    //ruota già in uso
    if(isInPending || isRotating || code[0].length !== 6) return
    setIsInPending(true)

    const codeResponse = await CustomServices.sendFetch<UseWheelCodeDto>(WheelCodeFetchUrl.UseWheelCode(code[0]) , {
        method: "GET",
    });
    code[1]("");
    if(codeResponse.error){
        if(codeResponse.error?.general) setPopUp({message:<p>{codeResponse.error.general}</p>});
        error[1](codeResponse.error);
        setIsInPending(false)
    }else{
      setPrize(codeResponse.data.award);
      setPrizeNumber(Math.floor(Math.random() * awards.length));
      setIsRotating(true);
    }  
  }

  function showPrize(){
    setIsRotating(false);
    setIsInPending(false);

    setPopUp({
      message:
        <div className={style["res-wheel"]}>
          <p className={style["res-wheel_title"]}>{prize === "riprova" ? "Hai perso" : "Hai vinto!"} </p>
          <p>{prize}</p>
        </div>, 
      buttons: null})
  }

  return <div className="max900 m_center">

    {(isStaff || isMaster) && <>
        <WheelCodeGenerator idWheelEvent={wheelEvent._id} />
      </>
    }

    <div className={style["table-award"]}>
      <p>PREMI IN PALIO</p>
      <ul>
        {wheelEvent.awards.map(award => {
          return <li key={award.name}>{award.name}</li>
        })}
      </ul>
    </div>
    
    <div className="btn-box btn-box_center">
    
      <div className={style["box-wheel"]}>
        <h2 className="t_center">{wheelEvent.name}</h2>
        {awards.length && <Wheel
            mustStartSpinning={isRotating}
            prizeNumber={prizeNumber}
            data={awards}
            perpendicularText={true}
            onStopSpinning={() => showPrize()}
            spinDuration={1}
            backgroundColors={['rgb(253 191 64)', 'rgb(52, 52, 52)']}
            textColors={["rgb(52, 52, 52)","rgb(255, 255, 255)"]}
            fontFamily="Helvetica"
            fontSize={22}

            outerBorderWidth={1}
            radiusLineWidth={1}

            pointerProps={{
              src: triangle,
              style: {rotate: "180deg", translate: "-50% -20%", top:0, left: "50%"}
            }}
          />
        }

        <Form onSubmit={validateSpeenCode} addClass={style["form-code"]}>
          <Input addClass={style["input-code"]} send={true} name="codice" label="inserisci il codice" value={code} error={error} minLength={6} maxLength={6}/>
        </Form>
      </div>
    
    </div>
  </div>
  }

export async function loaderWheelEventPage(name: string){

  let wheelEventResp = await CustomServices.sendFetch<WheelEventModel>(WheelEventFetchUrl.GetWheelEvent(name.replaceAll("-"," ")), {
      method: "GET",
  })

  if(wheelEventResp.error){
    alert(wheelEventResp.error.general)
    window.location.href = "../"
  }
  return wheelEventResp.data;
}

export default WheelEvent;