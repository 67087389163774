import { Schema } from "mongoose";
import { FC, useContext, useState } from "react";

import CustomServices from "../../services/services";
import GeneralStyle from "./../../App.module.css";
import { PopUpContext } from "./../../store/context";
import { CreateWheelCodeModel, WheelCodeFetchUrl } from "./../../model/WheelCode.model";

import style from "./WheelCodeGenerator.module.css"

const WheelCodeGenerator: FC<{idWheelEvent: Schema.Types.ObjectId/*, maxTry: number*/}> = ({idWheelEvent/*, maxTry*/}) => {

    const [wheelCode, setCode] = useState<string>("_ _ _ _ _ _");
    //const tentativi = useState<number>(1);
    const error = useState<{[name: string]: string|null}>({});

    const [popUp, setPopUp] = useContext(PopUpContext);

 
    async function GenerateWheelCode(e: React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();
        
        let body : CreateWheelCodeModel = {
            idWheelEvent,
            //maxTry: 1//Number(tentativi[0]),
        }
        
        const wheelCodeResponse = await CustomServices.sendFetch<string>(WheelCodeFetchUrl.GenerateWheelCodeEvent() , {
            method: "POST",
            body: JSON.stringify(body)
        });

        if(wheelCodeResponse.error){
            //TODO: popup error o errore alrt 24/06/2024
            if(wheelCodeResponse.error?.general) setPopUp({message:<p>{wheelCodeResponse.error.general}</p>});

            error[1](wheelCodeResponse.error);
        }else{
            setCode(wheelCodeResponse.data);
            // btn["disabled"] = false;
        }        
    }

    return (<div className={style["code-box"]}>
        <p>{wheelCode}</p>
        <button type="button" title="genera codice" 
            className={`${style["btn-code"]} ${GeneralStyle["btn"]} ${GeneralStyle["btn_awaiter"]} btn`}
            onClick={GenerateWheelCode}
            >GENERA CODICE</button>
    </div>)
}

export default WheelCodeGenerator;

