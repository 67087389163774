import { useLoaderData } from "react-router-dom";
import { Link } from "react-router-dom";

import style from "./stylepage/Gevents.module.css"

import CustomServices from "./../services/services";
import { WheelEventFetchUrl, WheelEventModel } from "./../model/WheelEvent.model";

function WheelEvents() {

  let events = useLoaderData() as [WheelEventModel];
  return (
    <div>
      <h2 className={`t_center ${style.title}`}>SCOPRI TUTTI GLI EVENTI</h2>
      <div className={style["card_event-box"]}>
        {events.map((item , index)=> {
          return(
            <div key={item.name} className={style.card_event}>
              {index % 2 !== 0 && <Link to={item.name} className={style.playLink}>Gioca</Link>}
              <div className={style["event_text-box"]}>
                <p>{item.name}</p>
                <p>{item.description.substring(0, 93) + "..."}</p>
                <Link to={item.name}>visita</Link>
              </div>
              
              {index % 2 === 0 && <Link to={item.name} className={style.playLink}>Gioca</Link>}
            </div>
          )
        })}
        {(!events.length) && <div className={style["no_event-box"]}>
          <p>Al momento non ci sono eventi disponibili</p>
        </div>}
      </div>
    </div>
  );
}
  
export async function loaderWheelEventsPage(){

  let wheeleventsResp = await CustomServices.sendFetch<[WheelEventModel]>(WheelEventFetchUrl.GetAllWheelEvents(), {
      method: "GET",
  })
  return wheeleventsResp.data;
}

export default WheelEvents;