import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { LoginContext, UrlContext } from "./../../store/context";
import style from "./Header.module.css";

const Header : FC = () => {

    const [user, setUser] = useContext(LoginContext);
    const [url, setUrl] = useContext(UrlContext);

    return <header className={style.header}>
        <Link  to="/" title="Home" className={style.title}>Adver<span>Play</span></Link>

        <nav className={style.nav}>
            {/* <NavLink  to="/events" title="Home">Eventi</NavLink> */}
            <NavLink  to="/wheel" title="Home">Eventi</NavLink>
            {user 
                ? <NavLink to="Dashboard">Profilo</NavLink>
                : <NavLink to="./access" onClick={() => setUrl(window.location.href)}>LogIn</NavLink> 
            }
        </nav>
    </header>
}

export default Header;